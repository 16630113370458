import { useEffect, useState, Suspense } from "react";
import { Ad1, Ad2, Ad3, Ad4, Loader, Video1, Video2 } from "../";
import { Card, Row, Typography, Button, Tag } from "antd";
import moment from "moment";
import i18next from "i18next";
import ErrorImage from "../../img/og-image.jpg";
import axios from "axios";
import "moment/locale/zh-cn";
import "./Home.css";
moment().locale("zh-cn");

const { Text, Title } = Typography;
const iframe = '<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FOrientalDailyNewsMalaysia%2Fvideos%2F944354143386607%2F&show_text=false&width=560&t=0" width="560" height="314" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>';
const Home = () => {
  const [article, setArticle] = useState([]);
  const [load, setLoad] = useState(50);
  const [done, setDone] = useState(undefined);

  const [iframe1,setIFrame1]=useState('');
  const [iframe2,setIFrame2]=useState('');
  const [iframe3,setIFrame3]=useState('');

  const replaceErrorImg = e => {
    e.target.onerror = null;
    e.target.src = ErrorImage;
  };

  const fetchData = async () => {
    axios.get('https://fiabci2024.orientaldaily.com.my/article.php').then((response)=>{
      const articleData=response.data

      setArticle(articleData);
      setDone(true);

    });

    var rand = Math.floor(Math.random() * 100);
    var rand2 = Math.floor(Math.random() * 100);
    // get content from an url using Promise
    await fetch(
      "https://fiabci2024.orientaldaily.com.my/admin/cache/pinvid.txt?i=" + rand + "" + rand2
    ).then(response => response.text()).then(data => { setIFrame1(data)});
    await fetch(
      "https://fiabci2024.orientaldaily.com.my/admin/cache/pinvid2.txt?i=" + rand + "" + rand2
      ).then(response => response.text()).then(data => { setIFrame2(data)});
    await fetch(
        "https://fiabci2024.orientaldaily.com.my/admin/cache/pinvid3.txt?i=" + rand + "" + rand2
        ).then(response => response.text()).then(data => { setIFrame3(data)});

    
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (!done) return <Loader />;
  return (
    <>
      <div className="home-page">
        <Suspense fallback={<Loader />}>
          {/*<Ad4/>*/}
          <div className="home_img"></div>
        </Suspense>
        <div className="article_container">
          {article.slice(0, load).map((art, index) => (
            <Row key={index}>
              {/*index===7 && <Ad1/>}
                {index===14 && <Ad2/>}
            {index===21 && <Ad3/>*/}
              {/*index===1 && <Video1/> }
                {//index===5 && <Video2/>*/}
              {index == 2 && iframe1 != "" && (
                <div className="player-wrapper" dangerouslySetInnerHTML={{__html: iframe1}}>
                </div>
              )}
              {index == 5 && iframe2 != "" && (
                <div className="player-wrapper" dangerouslySetInnerHTML={{__html: iframe2}}>
                </div>
              )}
              {index == 8 && iframe3 != "" && (
                <div className="player-wrapper" dangerouslySetInnerHTML={{__html: iframe3}}>
                </div>
              )}
              <Card hoverable className="article-news">
                <a href={art.url} target="_blank">
                  <div className="news-image-container">
                    <div>
                      <img
                        src={art.picurl}
                        className="news-image"
                        alt={art.id}
                        onError={replaceErrorImg}
                      />
                    </div>
                    <div className="article-text">
                      <Title className="news-title" level={5}>
                        {art.title}
                      </Title>
                      <Text className="news-date">
                        {moment(art.date).fromNow()}
                      </Text>
                      {art.pinnews && (
                        <Tag
                          className="pin-news"
                          color="red"
                          style={{ marginLeft: "1em", borderColor: "white" }}>
                          {i18next.t("热闻")}
                        </Tag>
                      )}
                      <Text className="news-text" style={{ marginTop: "1em" }}>
                        {art.description.length > 100
                          ? `${art.description.substring(0, 50)}...`
                          : art.description}
                      </Text>
                    </div>
                  </div>
                </a>
              </Card>
            </Row>
          ))}
        </div>
      </div>
      {/*
    <div className='load-page'>
      <Button className='load-button' type="primary" style={{marginBottom:'3em',backgroundColor:'#cb0c13',borderColor:'#cb0c13',height:'auto',fontSize:'15px',padding:'14px 50px'}}onClick={()=>setLoad((preLoad)=>preLoad+10)}>更多文章</Button>
    </div>
        */}
    </>
  );
};
export default Home;
